import TituloDaPagina from "../../../components/tituloDaPagina";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Stepper,
  Step,
  StepButton,
  StepLabel,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { connect } from "react-redux";
import {
  getFormulario,
  getListaProfissionais,
  getSolicAberta,
} from "../../../store/actions/CRTActions";
import Loading from "../../../components/loading";
import EstruturaDaPagina from "../../../components/estruturaDaPagina";
import statusTexto from "../../../rotulos/status";
import { Alert } from "@mui/material";

const Alterar = ({ auth, solicitacaoAberta }) => {
  const navigate = useNavigate();
  const {path} = useLocation();
  const [loading, setLoading] = useState(false);
  const steps = ["Dados CR", "Anexos", "Pagamento"];
  const [activeStep, setActiveStep] = useState();
  const [completed, setCompleted] = useState({});

  const [erroSolicAberta, setErroSolicAberta] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true)
      const resStatus = await getSolicAberta(auth.idusuario);
      // if(resStatus==='5') navigate(`/pendencias`)
    
      if (resStatus === false) {
        setErroSolicAberta(true);
        if(path !=='/empSolic') navigate('/empSolic');
      }
      else {
        setErroSolicAberta(false);
        configurarPagina(resStatus);
      };
    })();
  }, []);

  const configurarPagina = (status) => {
    
    switch (status) {
      case "0":
        setActiveStep(0);
        // setCompleted({});
        break;
      case "1":
        setActiveStep(0);
        // setCompleted({ 0: true, 1: true, 2: true });
        break;
      case "2":
        setActiveStep(0)
        break;
      case "3":
        setActiveStep(2);
        // setCompleted({ 0: true, 1: true });
        break;
      case "5":
        setActiveStep(0);
        // setCompleted({ 2: true });
        break;
      case "6":
        setActiveStep(0);
        break;
      case "7":
        setActiveStep(1);
        // setCompleted({ 0: true });
      case "10":
        setActiveStep(0);
        break;
    }

    
  };

  const buscarFormulario = async () => {
    const resposta = await getFormulario(auth.idusuario, auth.tipoUsuario);
    const resposta2 = await getListaProfissionais(auth.idusuario,auth.tipoUsuario);
    return
  };

  const etapasConcluidas = (status) => {
    switch (status) {
      case "7":
        setCompleted({ 0: true });
        break;
      case "3":
        setCompleted({ 0: true, 1: true });
        break;
      case "5":
        setCompleted({ 2: true });
        break;
      case "6":
        setCompleted({0:true, 1:true, 2:true});
        break
      case "10":
        setCompleted({0:true, 1:true, 2:true});
      default:
        setCompleted({});
    }
  };

  useEffect(() => {
    (async () => {
      // setLoading(true)
      const resStatus = await getSolicAberta(auth.idusuario);
      if (resStatus === false) {
        setErroSolicAberta(true);
        // navigate("/empSolic");
      }
      else {
        setErroSolicAberta(false);
        etapasConcluidas(resStatus);
      }
      await direcionar(activeStep)
    })();
  }, [activeStep]);

  const direcionar = async (activeStep) => {
    if (activeStep === 0) {
      const retornoFormulario = await buscarFormulario();
      setLoading(false)
      navigate("/empSolic/dados");
    } else if (activeStep === 1) {
      setLoading(false)
      navigate("/empSolic/anexos");
    } else if(activeStep===2){
      let rota = solicitacaoAberta.solic === "" ? "0" : solicitacaoAberta.solic;
      setLoading(false)
      navigate(`/empSolic/pagamento/${rota}`);
    }
    
    return 
  }

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleActive = (status) => {
    setActiveStep(status);
  };
  
  const currentPage = () => {
    const currentUrl = window.location.pathname;
    let res
    switch (currentUrl) {
      case '/empSolic/anexos':
        res = 'Anexar os documentos solicitados abaixo';
        break;
      case '/empSolic/pagamento/0':
        res = 'Finalização';
        break;
      default:
        res = 'Alterar CR';
    }
    return res
  }
  const titulo = currentPage()

  return (
    <EstruturaDaPagina>
     
      <TituloDaPagina titulo={titulo} />

      {solicitacaoAberta.status !== "0" && (
        <Box sx={{ textAlign: "center", mb: 2 }} component={Paper}>
          <Typography variant="h6">
            Solicitação: {solicitacaoAberta.solic}
          </Typography>
          <Typography variant="subtitle1">
            {statusTexto[solicitacaoAberta.status]}
          </Typography>
        </Box>
      )}
      {erroSolicAberta && (
        <Alert severity="error">Erro ao verificar solicitações</Alert>
      )} 
      {loading ? (<Loading status={loading}/>)
      : (
        <>
          <Stepper nonLinear activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepLabel
                  onClick={handleStep(index)}
                  sx={{ "&:hover": { cursor: "pointer" } }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
            <Outlet
              context={{
                handleActive: handleActive,
                setCompleted: setCompleted,
                completed: completed,
              }}
            />
        </>
      )}
    </EstruturaDaPagina>
  );
};

const mapStateToProps = (state) => ({
  formularioInicial: state.CRTReducer.formularioInicial,
  formularioTemporario: state.CRTReducer.formularioTemporario,
  formularioEditado: state.CRTReducer.formularioEditado,
  listaProfissionais: state.CRTReducer.listaProfissionais,
  listaProfissionaisTemporario: state.CRTReducer.listaProfissionaisTemporario,
  auth: state.AutenticacaoReducer.autenticacao,
  formularioValido: state.CRTReducer.formularioValido,
  solicitacaoAberta: state.CRTReducer.solicitacaoAberta,
});
export default connect(mapStateToProps)(Alterar);
