import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Paper,
  Grid,
  Typography,
  Alert,
  Snackbar,
  Backdrop,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { connect } from "react-redux";
import { horasExibicao } from "../../../helpers";

import { useNavigate } from "react-router-dom";
import { objetosSaoIguais } from "../../../helpers";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useEffect, useState } from "react";
import useStyles from "./styles";
import { salvarAlteracoes } from "../../../store/actions/CRTActions";
import TabelaFuncionario from "./tabelaFuncionario";
import CloseIcon from "@mui/icons-material/Close";
import { resetPendencias } from "../../../store/actions/CRTActions";

const ConferirCRT = ({
  fi,
  ft,
  li,
  lt,
  alteracoes,
  handleClose,
  pendenciasFuncionarios,
  pendenciasEmpresa,
  msgErroValida,
  capitalSocial,
  tipoDeEstabelecimento,
  subtipoDeEstabelecimento,
  alteracaoIsenta,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState({});

  const handleClick = (e) => {
    setOpen({ [e]: !open[e] });
  };

  const [erro, setErro] = useState({ erro: false, msg: "" });
  const [loading, setLoading] = useState(false);
  const [dadosEnviados, setDadosEnviados] = useState(false);
  const [pendencias, setPendencias] = useState(pendenciasFuncionarios);
  const [pendenciasEmp, setPendenciasEmp] = useState(pendenciasEmpresa);
  const [erroValida, setErroValida] = useState(msgErroValida);

  const salvar = async (dados) => {
    setLoading(true);
    const resposta = await salvarAlteracoes(dados);
    if (!resposta.erro) {
      setErro(resposta);
      setDadosEnviados(true);
      if (alteracaoIsenta) {
        setTimeout(() => {
          navigate("/histSolic");
        }, 1000);
      } else {
        setTimeout(() => {
          navigate("/empSolic/anexos");
        }, 1000);
      }
    } else {
      setLoading(false);
      setErro(resposta);
    }
  };

  const voltar = () => {
    resetPendencias();
    handleClose();
  }
  return (
    <Box component={Paper} p={{ xs: 1, md: 5 }} variant="outlined">     
      <Grid container direction="column" columnSpacing={2}>
        <Grid item>
          <CloseIcon
            onClick={() => voltar()}
            sx={{ float: "right", "&:hover": { cursor: "pointer" } }}
          />
        </Grid>
        <Grid item>
          <Typography
            component="h1"
            variant="h6"
            gutterBottom
            align="center"
            color="primary.main"
            sx={{ fontWeight: "bold" }}
          >
            Revise os dados alterados e avance para a próxima etapa.
          </Typography>
          {alteracaoIsenta && <Alert severity="warning">Para alteração no Email e/ou Telefone não há cobrança de taxa ou necessidade de Anexos</Alert>}
        </Grid>
        <Grid item>
          {erro.erro && <Alert severity="error">{erro.msg}</Alert>}
          {erroValida && <Alert severity="error">{erroValida}</Alert>}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={1000}
            open={dadosEnviados}
          >
            <Alert severity="success">
              Dados salvos com sucesso! Anexe os documentos
            </Alert>
          </Snackbar>
        </Grid>
        {!erroValida &&
        <Grid item>
          <Typography
            component="h2"
            variant="h6"
            gutterBottom
            align="left"
            color="primary.main"
            sx={{ fontWeight: "bold" }}
          >
            Empresa
          </Typography>
          {objetosSaoIguais(fi, ft) && pendenciasEmp.assistencia === true ? (
            <Typography variant="body">Nenhuma alteração realizada</Typography>
          ) : (
            <Table
              sx={{
                border: "solid thin gray",
                mx: "auto",
              }}
              stickyHeader
            >
              <TableHead>
                <TableRow className={classes.cabecalho}>
                  <TableCell>Campo</TableCell>
                  <TableCell>Antes</TableCell>
                  <TableCell>Depois</TableCell>
                  <TableCell>Observação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fi.nome !== ft.nome && (
                  <TableRow>
                    <TableCell>Nome Fantasia</TableCell>
                    <TableCell>{fi.nome}</TableCell>
                    <TableCell>{ft.nome}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {fi.razao !== ft.razao && (
                  <TableRow>
                    <TableCell>Razão social</TableCell>
                    <TableCell>{fi.razao}</TableCell>
                    <TableCell>{ft.razao}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {fi.capitalsocial_idcapitalsocial !==
                  ft.capitalsocial_idcapitalsocial && (
                  <TableRow>
                    <TableCell>Capital social</TableCell>
                    <TableCell>
                      {
                        capitalSocial.find(
                          (c) =>
                            fi.capitalsocial_idcapitalsocial ===
                            c.idcapitalsocial
                        ).texto
                      }
                    </TableCell>
                    <TableCell>
                      {
                        capitalSocial.find(
                          (c) =>
                            ft.capitalsocial_idcapitalsocial ===
                            c.idcapitalsocial
                        ).texto
                      }
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {fi.idtipo !== ft.idtipo && (
                  <TableRow>
                    <TableCell>Tipo de estabelecimento</TableCell>
                    <TableCell>{fi.tipo}</TableCell>
                    <TableCell>
                      {
                        tipoDeEstabelecimento.find(
                          (t) => ft.idtipo === t.idtipo
                        ).tipo
                      }
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {fi.idsubtipo !== ft.idsubtipo && (
                  <TableRow>
                    <TableCell>Subtipo de estabelecimento</TableCell>
                    <TableCell>{fi.subtipo}</TableCell>
                    <TableCell>
                      {
                        subtipoDeEstabelecimento.find(
                          (s) => ft.idsubtipo === s.idsubtipo
                        )?.subtipo
                      }
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {fi.cep !== ft.cep && (
                  <TableRow>
                    <TableCell>CEP</TableCell>
                    <TableCell>{fi.cep}</TableCell>
                    <TableCell>{ft.cep}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {fi.logradouro !== ft.logradouro && (
                  <TableRow>
                    <TableCell>Logradouro</TableCell>
                    <TableCell>{fi.logradouro}</TableCell>
                    <TableCell>{ft.logradouro}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {fi.numero !== ft.numero && (
                  <TableRow>
                    <TableCell>Número</TableCell>
                    <TableCell>{fi.numero}</TableCell>
                    <TableCell>{ft.numero}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {fi.complenum !== ft.complenum && (
                  <TableRow>
                    <TableCell>Complemento</TableCell>
                    <TableCell>{fi.complenum}</TableCell>
                    <TableCell>{ft.complenum}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {fi.bairro !== ft.bairro && (
                  <TableRow>
                    <TableCell>Bairro</TableCell>
                    <TableCell>{fi.bairro}</TableCell>
                    <TableCell>{ft.bairro}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {fi.cidade !== ft.cidade && (
                  <TableRow>
                    <TableCell>Cidade</TableCell>
                    <TableCell>{fi.cidade}</TableCell>
                    <TableCell>{ft.cidade}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {fi.telefone !== ft.telefone && (
                  <TableRow>
                    <TableCell>Telefone</TableCell>
                    <TableCell>{fi.telefone}</TableCell>
                    <TableCell>{ft.telefone}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {fi.celular !== ft.celular && (
                  <TableRow>
                    <TableCell>Celular</TableCell>
                    <TableCell>{fi.celular}</TableCell>
                    <TableCell>{ft.celular}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {fi.email1 !== ft.email1 && (
                  <TableRow>
                    <TableCell>Email principal</TableCell>
                    <TableCell>{fi.email1}</TableCell>
                    <TableCell>{ft.email1}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {fi.email2 !== ft.email2 && (
                  <TableRow>
                    <TableCell>Email secundário</TableCell>
                    <TableCell>{fi.email2}</TableCell>
                    <TableCell>{ft.email2}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
                {((fi.hr_seg !== ft.hr_seg) || (pendenciasEmp.assistencia.seg)) && (
                  <TableRow>
                    <TableCell>Segunda-feira</TableCell>
                    <TableCell>{horasExibicao(fi.hr_seg)}</TableCell>
                    <TableCell>{horasExibicao(ft.hr_seg)}</TableCell>
                    <TableCell>
                    {pendenciasEmp.assistencia.seg &&
                    (
                      <Alert
                        severity="error"
                        sx={{ width: "max-content" }}
                      >
                        {pendenciasEmp.assistencia.seg}
                      </Alert>
                    )}
                    </TableCell>
                  </TableRow>
                )}
                {((fi.hr_ter !== ft.hr_ter)  || (pendenciasEmp.assistencia.ter)) && (
                  <TableRow>
                    <TableCell>Terça-feira</TableCell>
                    <TableCell>{horasExibicao(fi.hr_ter)}</TableCell>
                    <TableCell>{horasExibicao(ft.hr_ter)}</TableCell>
                    <TableCell>
                    {pendenciasEmp.assistencia.ter &&
                    (
                      <Alert
                        severity="error"
                        sx={{ width: "max-content" }}
                      >
                        {pendenciasEmp.assistencia.ter}
                      </Alert>
                    )}
                    </TableCell>
                  </TableRow>
                )}
                {((fi.hr_qua !== ft.hr_qua)  || (pendenciasEmp.assistencia.qua)) && (
                  <TableRow>
                    <TableCell>Quarta-feira</TableCell>
                    <TableCell>{horasExibicao(fi.hr_qua)}</TableCell>
                    <TableCell>{horasExibicao(ft.hr_qua)}</TableCell>
                    <TableCell>
                    {pendenciasEmp.assistencia.qua &&
                    (
                      <Alert
                        severity="error"
                        sx={{ width: "max-content" }}
                      >
                        {pendenciasEmp.assistencia.qua}
                      </Alert>
                    )}
                    </TableCell>
                  </TableRow>
                )}
                {((fi.hr_qui !== ft.hr_qui)  || (pendenciasEmp.assistencia.qui)) && (
                  <TableRow>
                    <TableCell>Quinta-feira</TableCell>
                    <TableCell>{horasExibicao(fi.hr_qui)}</TableCell>
                    <TableCell>{horasExibicao(ft.hr_qui)}</TableCell>
                    <TableCell>
                    {pendenciasEmp.assistencia.qui &&
                    (
                      <Alert
                        severity="error"
                        sx={{ width: "max-content" }}
                      >
                        {pendenciasEmp.assistencia.qui}
                      </Alert>
                    )}
                    </TableCell>
                  </TableRow>
                )}
                {((fi.hr_sex !== ft.hr_sex)  || (pendenciasEmp.assistencia.sex)) && (
                  <TableRow>
                    <TableCell>Sexta-feira</TableCell>
                    <TableCell>{horasExibicao(fi.hr_sex)}</TableCell>
                    <TableCell>{horasExibicao(ft.hr_sex)}</TableCell>
                    <TableCell>
                    {pendenciasEmp.assistencia.sex &&
                    (
                      <Alert
                        severity="error"
                        sx={{ width: "max-content" }}
                      >
                        {pendenciasEmp.assistencia.sex}
                      </Alert>
                    )}
                    </TableCell>
                  </TableRow>
                )}
                {((fi.hr_sab !== ft.hr_sab)  || (pendenciasEmp.assistencia.sab)) && (
                  <TableRow>
                    <TableCell>Sábado</TableCell>
                    <TableCell>{horasExibicao(fi.hr_sab)}</TableCell>
                    <TableCell>{horasExibicao(ft.hr_sab)}</TableCell>
                    <TableCell>
                    {pendenciasEmp.assistencia.sab &&
                    (
                      <Alert
                        severity="error"
                        sx={{ width: "max-content" }}
                      >
                        {pendenciasEmp.assistencia.sab}
                      </Alert>
                    )}
                    </TableCell>
                  </TableRow>
                )}
                {((fi.hr_dom !== ft.hr_dom)  || (pendenciasEmp.assistencia.dom)) && (
                  <TableRow>
                    <TableCell>Domingo</TableCell>
                    <TableCell>{horasExibicao(fi.hr_dom)}</TableCell>
                    <TableCell>{horasExibicao(ft.hr_dom)}</TableCell>
                    <TableCell>
                    {pendenciasEmp.assistencia.dom &&
                    (
                      <Alert
                        severity="error"
                        sx={{ width: "max-content" }}
                      >
                        {pendenciasEmp.assistencia.dom}
                      </Alert>
                    )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          )}
          <br />
          <br />
          <Typography
            component="h2"
            variant="h6"
            gutterBottom
            align="left"
            color="primary.main"
            sx={{ fontWeight: "bold" }}
          >
            Profissionais
          </Typography>
          {objetosSaoIguais(li, lt) ? (
            <Typography variant="body">Nenhuma alteração realizada</Typography>
          ) : (
            <Table
              sx={{
                border: "solid thin gray",
                mx: "auto",
              }}
              stickyHeader
            >
              <TableHead className={classes.cabecalho}>
                <TableCell>Profissional</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Observação</TableCell>
                <TableCell>Ver mais</TableCell>
              </TableHead>
              <TableBody>
                {lt.map((pro, index) =>
                  !objetosSaoIguais(pro, li[index]) ? (
                    <>
                      <TableRow key={index}>
                        <TableCell>{pro.nome}</TableCell>
                        <TableCell>
                          {pro.cpf === li[index]?.cpf ? "Editado" : "Novo"}
                        </TableCell>
                        <TableCell>
                          {pendencias[0]?.find(e => e.hasOwnProperty(pro.idprofissional)) !==
                            undefined && (
                            <Alert
                              severity="error"
                              sx={{ width: "max-content" }}
                            >
                              {pendencias[0]?.find(e => e.hasOwnProperty(pro.idprofissional))?.[pro.idprofissional]}
                            </Alert>
                          )}
                        </TableCell>
                        <TableCell>
                          {" "}
                          {open[index] ? (
                            <ExpandLessIcon
                              onClick={() => handleClick(index)}
                              sx={{ "&:hover": { cursor: "pointer" } }}
                            />
                          ) : (
                            <ExpandMoreIcon
                              onClick={() => handleClick(index)}
                              sx={{ "&:hover": { cursor: "pointer" } }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                      {open[index] && (
                        <>
                          <TableRow>
                            <TableCell
                              sx={{ backgroundColor: "background.main" }}
                              colSpan={6}
                              align="center"
                            >
                              <Box sx={{ m: 1 }}>
                                <TabelaFuncionario
                                  ehNovo={pro.cpf !== li[index]?.cpf}
                                  inicio={li[index]}
                                  fim={pro}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )
                )}
              </TableBody>
            </Table>
          )}
        </Grid>}

        <Box sx={{ display: "flex", gap: 5, justifyContent: "center", my: 2 }}>
          <Button
            onClick={() => voltar()}
            variant="outlined"
            color="error"
          >
            Voltar
          </Button>
          <Tooltip title={pendencias.length > 0 ? 'Resolva as pendências para prosseguir' : ''}>
            <span>
              <Button
                onClick={() => salvar(alteracoes)}
                variant="outlined"
                color="primary"
                disabled={(pendencias.length) > 0 || (pendenciasEmp.assistencia != true)}
              >
                Avançar
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  fi: state.CRTReducer.formularioInicial,
  ft: state.CRTReducer.formularioTemporario,
  li: state.CRTReducer.listaProfissionais,
  lt: state.CRTReducer.listaProfissionaisTemporario,
  alteracoes: state.CRTReducer.alteracoes,
  pendenciasFuncionarios: state.CRTReducer.pendenciasFuncionarios,
  pendenciasEmpresa: state.CRTReducer.pendenciasEmpresa,
  msgErroValida: state.CRTReducer.msgErroValida,
  capitalSocial: state.CRTReducer.capitalSocial,
  tipoDeEstabelecimento: state.CRTReducer.tipoDeEstabelecimento,
  subtipoDeEstabelecimento: state.CRTReducer.subtipoDeEstabelecimento,
});
export default connect(mapStateToProps)(ConferirCRT);
