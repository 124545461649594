//react router e redux
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import { dataIgualBanco, dataAmericana } from "../../../helpers";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { apiEmpresa } from "../../../services/apiEmpresa";
// components formik
import {
  useFormik,
  FieldArray,
  ErrorMessage,
  FormikProvider,
  getIn,
  Field,
} from "formik";

// material ui

import {
  Button,
  Divider,
  Grid,
  InputLabel,
  Typography,
  Paper,
  TextField,
  Select,
  List,
  ListItem,
  CircularProgress,
  Tooltip,
  IconButton,
  Skeleton,
  Snackbar,
  Backdrop,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";

import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import Collapse from "@material-ui/core/Collapse";
import { MenuItem } from "@mui/material";

import { Box } from "@mui/system";
import useStyles from "./styles";
import Dialog from "@mui/material/Dialog";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// components
import InputMask from "react-input-mask";
import ConferirCRT from "../../../components/crt/conferirCRT";
import ErroApi from "../../../components/erroApi";
import { semanaEmpresa } from "../../../rotulos/semanaEmpresa";
import { semanaProfissional } from "../../../rotulos/semanaProfissional";
import { rotulosEmpresa } from "../../../rotulos/empresa";
import { rotulosProfissional } from "../../../rotulos/profissional";
import CRTexto from "../../../components/crt/crtTexto";
import textos from "../../../rotulos/textos";

// funções
import validacaoFormulario from "./validacaoFormulario";
import {
  postListaProfissionaisTemporario,
  resetProfissionaisTemporario,
  postFormularioTemporario,
  resetTemporario,
  verificarAlteracoes,
  getCapitalSocial,
  getTipoEstab,
  getSubtipoEstab,
  getSolicAberta
} from "../../../store/actions/CRTActions";

import { objetosSaoIguais } from "../../../helpers";
import { formatarHoraBD } from "../../../helpers";
import { removeMaskCpfOrCnpj } from "../../../helpers";

import { useNavigate } from "react-router-dom";

//busca cpf
const initialState = {
  erro: [],
  msg: [],
};

const FormularioCRT = ({
  formularioInicial,
  formularioTemporario,
  formularioEditado,
  listaProfissionais,
  listaProfissionaisTemporario,
  auth,
  formularioValido,
  solicitacaoAberta,
  capitalSocial,
  tipoDeEstabelecimento,
  subtipoDeEstabelecimento,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [formularioInalterado, setFormularioInalterado] = useState(false);
  const [erroBuscaCPF, setErroBuscaCPF] = useState(initialState);
  const [erroDuploCPF, setErroDuploCPF] = useState(initialState)
  const [openProfissional, setOpenProfissional] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const [cepNaoEncontrado, setCepNaoEncontrado] = useState(false);
  const [loadingCpf, setLoadingCpf] = useState({});
  const [erroFormulario, setErroFormulario] = useState(false);
  const [loadingEnviar, setLoadingEnviar] = useState(false);
  const [validarEnquantoDigita, setValidarEnquantoDigita] = useState(false);
  const [alteracaoNoHorario, setAlteracaoNoHorario] = useState(false);
  const [pendencias, setPendencias] = useState([]);
  const [erroDiretor, setErroDiretor] = useState();
  const [erroSemHorario, setErroSemHorario] = useState();
  const [profissionalProvisorio, setProfissionalProvisorio] = useState([]);
  const [alteracaoIsenta, setAlteracaoIsenta] = useState(false)

  useEffect(() => {
    if(solicitacaoAberta.status === '5'){
      (async() => {
        setLoading(true);
        try{
          const {data} = await apiEmpresa.get('/formulario', {
            params: {
              idEmp: auth.idusuario,
            }
          }
          )
          
          if(data[2]?.pendencias !== undefined){
            let array = [];
            data[2].pendencias.map(element => array.push(element.campo));
            setPendencias(array);
          }

        }
        catch(error){
          console.log('error ', error)
        }
        setLoading(false);
      })()
    }
  },[])

  useEffect(() => {
    return () => {
      cancelarAlteracoes(formik.handleReset);
    };
  }, []);

  const handleClick = (indice, value) => {
    let oldStatus = openProfissional;
    setOpenProfissional({ ...openProfissional, [indice]: value });
  };

  const cancelarAlteracoes = (reset) => {
    // limpa temporário
    resetTemporario();
    resetProfissionaisTemporario();
    // tira mensagem de formulário inalterado
    setFormularioInalterado(false);
    setValidarEnquantoDigita(false);
    // reseta formulário
    reset();
  };

  const onBlurCep = async (ev, setFieldValue) => {
    const { value } = ev.target;
    const cep = value.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    } else {
      setLoadingCep(true);
      try {
        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`
        );

        setCepNaoEncontrado(data.erro);
        setFieldValue("empresa.logradouro", data.logradouro || "");
        setFieldValue("empresa.bairro", data.bairro || "");
        setFieldValue("empresa.cidade", data.localidade || "");
        setFieldValue("empresa.uf_endereco", data.uf || "");
        setFieldValue("empresa.numero", "");
        setFieldValue("empresa.complenum", "");
        setLoadingCep(false);
      } catch (error) {
        setLoadingCep(false);
        return;
      }
    }
  };

  const removerProfissionalProvisorio = (index) => {
    const copiaArray = [...profissionalProvisorio];
    copiaArray.splice(index, 1);
    setProfissionalProvisorio(copiaArray);
  }
  
  const removerErroBuscaCPF = (index) => {
    setErroBuscaCPF((prevState) => ({
      ...prevState,
      erro: {
        ...prevState.erro,
        [index]: false
      },
      msg: {
        ...prevState.msg,
        [index]: '',
      },
    }));
  }

  const removerErroDuploCPF = (index) => {
    document.querySelector('#btn-avancar').disabled = false;
    setErroDuploCPF((prevState) => ({
      ...prevState,
      erro: {
        ...prevState.erro,
        [index]: false
      },
      msg: {
        ...prevState.msg,
        [index]: '',
      },
    }));
  }

  const onBlurCpf = async (index, setFieldValue, inputCpf, inputData) => {
    let xyz = formik.values.lista[formik.values.lista.length - 1].cpf
    const cpfLimpo = xyz.replace(/[.-]/g, '')
    const lenArray = formik.values.lista.length-1

    for (let i = 0; i < lenArray; i++) {
      if (formik.values.lista[i].cpf.toString() === cpfLimpo.toString()) {
        document.querySelector('#btn-avancar').disabled = true;
        setErroDuploCPF((prevState) => ({
          ...prevState,
          erro: {
            ...prevState.erro,
            [index]: true
          },
          msg: {
            ...prevState.msg,
            [index]: "Profissional já inserido",
          },
        }))
  
        //zera profissionalProvisorio
        removerProfissionalProvisorio(index)
  
        setFieldValue(`lista[${index}].nome`, "")
        setLoadingCpf((prevState) => ({
          ...prevState,
          [index]: false,
        }))
        return
      }
    }
    inputCpf = removeMaskCpfOrCnpj(inputCpf);
    if ((inputCpf === "") | (inputData === "")) {
      return;
    }
    if (inputCpf.length !== 11) {
      return;
    } else {
      setLoadingCpf((prevState) => ({
        ...prevState,
        [index]: true,
      }));
      let data;
      try {
        ({ data } = await apiEmpresa.get("/pfporcpf", {
          params: {
            idEmp: auth.idusuario,
            cpf: inputCpf,
            dtnasc: moment(inputData, "DD/MM/YYYY").format("YYYY-MM-DD"),
          },
        }))
        //se deu certo a busca, 
        //coloco o erro como false e mensagem vazia para o cpf de index correspondente
        removerErroBuscaCPF(index)
        removerErroDuploCPF(index)

        //profissional provisorio
        if(data[0].provisorio === "sim"){
          setProfissionalProvisorio(prevState => {
            const newState = [...prevState];
            newState[index] = data[0]; 
            return newState; 
          });
        }else{
          removerProfissionalProvisorio(index)
        }

        setFieldValue(`lista[${index}].nome`, data[0].nome);
        setFieldValue(`lista[${index}].idprofissional`, data[0].idprofissional);
        setLoadingCpf((prevState) => ({
          ...prevState,
          [index]: false,
        }));
      } catch (error) {
        //em caso de erro na busca por cpf, salvo erro como true e guardo a mensagem de erro (por index do cpf)
        setErroBuscaCPF((prevState) => ({
          ...prevState,
          erro: {
            ...prevState.erro,
            [index]: true
          },
          msg: {
            ...prevState.msg,
            [index]: data.msg,
          },
        }));

        //zera profissionalProvisorio
        removerProfissionalProvisorio(index)

        setFieldValue(`lista[${index}].nome`, "");
        setLoadingCpf((prevState) => ({
          ...prevState,
          [index]: false,
        }));
        return;
      }
    }
  };

  const copiarHorario = (index) => {
    formik.setFieldValue(
      `empresa[${semanaEmpresa[index + 1]}]`,
      formik.values.empresa[semanaEmpresa[index]]
    );
  };

  const copiarHorarioProfissional = (index, indexProfissional) => {
    formik.setFieldValue(
      `lista[${indexProfissional}][${semanaProfissional[index + 1]}]`,
      formik.values.lista[indexProfissional][semanaProfissional[index]]
    );
  };

  const adicionarProfissional = (index) => {
    formik.setFieldValue("lista", [
      ...formik.values.lista,
      {
        nome: "",
        cpf: "",
        pro_vinculo: "",
        pro_salario: "",
        pro_tipo: "",
        dtnasc: "",
        dataini: moment().format("YYYY-MM-DD"),
        datafim: "0000-00-00",
        pro_hora_seg: "",
        pro_hora_ter: "",
        pro_hora_qua: "",
        pro_hora_qui: "",
        pro_hora_sex: "",
        pro_hora_sab: "",
        pro_hora_dom: "",
        idusuario: "",
        escala: false,
      },
    ]);
    handleClick(formik.values.lista.length, true);
  };

  const formik = useFormik({
    initialValues: !formularioEditado
      ? { empresa: formularioInicial, lista: listaProfissionais }
      : { empresa: formularioTemporario, lista: listaProfissionaisTemporario },
    enableReinitialize: true,
    validationSchema: validacaoFormulario,
    validateOnChange: validarEnquantoDigita,
    validationOnBlur: validarEnquantoDigita,

    onSubmit: async (values) => {
      setLoading(true);
      setErroFormulario(false);

      values.empresa.cep = removeMaskCpfOrCnpj(values.empresa.cep);
      values.empresa.hr_seg = formatarHoraBD(values.empresa.hr_seg);
      values.empresa.hr_ter = formatarHoraBD(values.empresa.hr_ter);
      values.empresa.hr_qua = formatarHoraBD(values.empresa.hr_qua);
      values.empresa.hr_qui = formatarHoraBD(values.empresa.hr_qui);
      values.empresa.hr_sex = formatarHoraBD(values.empresa.hr_sex);
      values.empresa.hr_sab = formatarHoraBD(values.empresa.hr_sab);
      values.empresa.hr_dom = formatarHoraBD(values.empresa.hr_dom);

      values.lista.map((item) => {
        item.cpf = removeMaskCpfOrCnpj(item.cpf);
        item.dataini = dataAmericana(item.dataini);
        item.datafim = dataAmericana(item.datafim);
        item.pro_hora_seg = formatarHoraBD(item.pro_hora_seg);
        item.pro_hora_ter = formatarHoraBD(item.pro_hora_ter);
        item.pro_hora_qua = formatarHoraBD(item.pro_hora_qua);
        item.pro_hora_qui = formatarHoraBD(item.pro_hora_qui);
        item.pro_hora_sex = formatarHoraBD(item.pro_hora_sex);
        item.pro_hora_sab = formatarHoraBD(item.pro_hora_sab);
        item.pro_hora_dom = formatarHoraBD(item.pro_hora_dom);
      });

      postFormularioTemporario(values.empresa);
      postListaProfissionaisTemporario(values.lista);

      await verificarAlteracoes(
        formularioInicial,
        formik.values.empresa,
        listaProfissionais,
        formik.values.lista
      );

      setLoading(false);
      setOpen(true);
    },
  });
  
  const enviar = async () => {
    // Confere se houve alteração no formulário
    setLoading(true);
    if (!validarEnquantoDigita) setValidarEnquantoDigita(true);
    const excludedFields = ["email1", "email2", "telefone", "celular"];

    console.log(formik.values.lista.length <= formik.initialValues.lista.length)
    const isOnlyEmailsAndTelefoneModified = (
      Object.keys(formik.values.empresa).some(field => {
        return (
          formik.values.empresa[field] !== formik.initialValues.empresa[field] &&
          !excludedFields.includes(field)
        );
      }) 
    );
    
      if (!isOnlyEmailsAndTelefoneModified && 
        (formik.values.lista === formik.initialValues.lista)) {
        setAlteracaoIsenta(true)
      } else {
        setAlteracaoIsenta(false)
    }
    if (
      !formularioEditado &&
      objetosSaoIguais(formik.values, formik.initialValues)
      ) {
        setFormularioInalterado(true);
        setLoading(false);
        setTimeout(() => setFormularioInalterado(false), 2000);
      return;
    } else {
      setFormularioInalterado(false);
    }
    
    verificarHorarios();
    
    //se não tiver erro de Diretor e erro de faltar horários, envia o form
    if(verificarDiretor(formik.values.lista) && verificarHorariosProfissional(formik.values.lista)){
      formik.handleSubmit();
    }
    
    window.scrollTo(0, 0);
    // useEffect monitora erros e se houver algum sobe para o topo da página
    setLoading(false);
  };
  
  // useEffect(() => {
    // 	(async () => {
      //     let tipo = formik.values.empresa.idtipo;
      //     if (tipo !== undefined) await getSubtipoEstab(tipo);
      //   })();
      // }, [formik.values.empresa.idtipo]);
      
      const formatarComoTexto = (status) => {
        const statusParaTexto = ["2", "3", "4", "6", "7", "8", "9", "10"];
        return statusParaTexto.find((e) => e === status) !== undefined;
      };
      
      const verificarHorarios = () => {
		let dadosIniciais = [
      formularioInicial.hr_seg,
      formularioInicial.hr_ter,
      formularioInicial.hr_qua,
      formularioInicial.hr_qui,
      formularioInicial.hr_sex,
      formularioInicial.hr_sab,
      formularioInicial.hr_dom,
    ];
    let alterou = false;
    
    for (let i = 0; i < dadosIniciais.length; i++) {
      if (
        dadosIniciais[i] !==
        formatarHoraBD(formik.values.empresa[semanaEmpresa[i]])
        ) {
          if (
            dadosIniciais[i] === null &&
            formatarHoraBD(formik.values.empresa[semanaEmpresa[i]]) === ""
            )
            continue;
            alterou = true;
            break;
          }
        }
        setAlteracaoNoHorario(alterou);
      }
      
      const verificarHorariosProfissional = (profissionais) => {
        let faltandoHorario = false
        profissionais.map((profissional) => {
          if(profissional.escala === false){
            //verifica se pelo menos um dia da semana tem horário preenchido
            if(
              (profissional.pro_hora_seg === "" || profissional.pro_hora_seg === null) &&
              (profissional.pro_hora_ter === "" || profissional.pro_hora_ter === null) &&
              (profissional.pro_hora_qua === "" || profissional.pro_hora_qua === null) &&
              (profissional.pro_hora_qui === "" || profissional.pro_hora_qui === null) &&
              (profissional.pro_hora_sex === "" || profissional.pro_hora_sex === null) &&
              (profissional.pro_hora_sab === "" || profissional.pro_hora_sab === null) &&
              (profissional.pro_hora_dom === "" || profissional.pro_hora_dom === null) 
              ){
          faltandoHorario = true
        }
      }
    })
    if(faltandoHorario){
      setErroSemHorario(textos.nenhumHorario)
      return false
    }else{
      setErroSemHorario('');
      return true
    }
  }
  
  const verificarDiretor = (lista) => {
    //verifica se foi cadastrado somente um RT. Se tiver mais ou menos, retorna false 
    let diretores = 0
    lista.map((profissional) => {
      if(profissional.pro_tipo == 'diretor') diretores ++
    })
    
    if(diretores > 1) {
      setErroDiretor(textos.maisDeUmRT)
      return false
    }
    
    if(diretores == 0) {
      setErroDiretor(textos.presencaRT)
      return false
    }
    
    setErroDiretor('')
    return true
  }
  
  const estaPendente =  (campo) =>  {
    let statusPendente = solicitacaoAberta.status==='5';
    let campoPendente = pendencias.includes(campo)
    if(!statusPendente) return true;
    return campoPendente;
  }
  
  
  useEffect(() => {
    (async () => {
      let valueTipo = formik.values.empresa.idtipo;
      let valueTipoInicial = formik.initialValues.empresa.idtipo
      await getSubtipoEstab(valueTipo);
      if(valueTipo !== valueTipoInicial){
        formik.setFieldValue('empresa.idsubtipo','0');
      }
    })()
  },[formik.values.empresa.idtipo])
  
  const desabilitaLogradouro = () => {
    //retorna true se precisa desabilitar (logradouro preenchido e não está pendente)
    // returna false se precisa habilitar (sem logradouro ou pendente)
    if(formik.values.empresa.logradouro !== "" && !estaPendente('logradouro')){
      return true
    }else{
      return false
    }
  }
  
  const desabilitaCEP = () => {
    if ((formik.values.empresa.cep !== "" && !estaPendente('cep'))) {
      return true
    } else {
      return false
    }
    
  }
  
  return (
    <>
      {solicitacaoAberta.status !== "0" && (
        <Alert severity="error">
          <Box sx={{display:'flex' , flexDirection:'column', gap:4}}>
          <Box sx={{ display: "flex", gap: 1 }}>
            {solicitacaoAberta.msg}. Para mais detalhes acesse seu protocolo:
            <Link
              sx={{ "&:hover": { cursor: "pointer" } }}
              onClick={() => navigate(`/histSolic/${solicitacaoAberta.solic}`)}
              >
              {solicitacaoAberta.solic}
            </Link>
            </Box>
            {pendencias && solicitacaoAberta.status==='5' &&(
              <Box>
                <List>
                  <ListItem>- Confira as pendências nos dados informados e anexos</ListItem>
                  {solicitacaoAberta.mensagemPendencia && (<ListItem>- {solicitacaoAberta.mensagemPendencia}</ListItem>)}
                </List>
                {/* <Typography>Confira as irregularidades abaixo: </Typography>
                <List>
                 {pendencias.map(pend => (
                  <ListItem>{ rotulosEmpresa[pend]}</ListItem>
                 ))}
                </List> */}
              </Box>
            )}
          </Box>
        </Alert>
      )}
      {loading && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Skeleton
            variant="rectangular"
            height={"20vh"}
            animation="wave"
            sx={{ opacity: 0.3 }}
          />
          <Skeleton
            variant="rectangular"
            height={"20vh"}
            animation="wave"
            sx={{ opacity: 0.3 }}
          />
          <Skeleton
            variant="rectangular"
            height={"20vh"}
            animation="wave"
            sx={{ opacity: 0.3 }}
          />
        </Box>
      )}
      {!formularioValido && <ErroApi msg="Formulário indisponível" />}
      {!loading && formularioValido && (
        <>
          {((Object.keys(formik.errors).length > 0) || (erroDiretor) || (erroSemHorario)) && (
            <Alert id="lista-erros" severity="error" mb={2}>
              <Typography variant="h6">Confira as informações</Typography>
              <List>
                {formik.errors.empresa &&
                  Object.keys(formik.errors.empresa).map((key, index) => (
                    <ListItem key={`emp-${index}`}>
                      {formik.errors.empresa[key]}
                    </ListItem>
                  ))}
                {formik.errors.lista?.map((profissional, index) => (
                  <>
                    {profissional !== undefined && (
                      <ListItem key={`pro-${index}`}>
                        {profissional.cpf === undefined ? (
                          <Box>
                            Dados incorretos - {formik.values.lista[index].nome}{" "}
                            ({formik.values.lista[index].cpf})
                            <List sx={{ pl: 2 }} disablePadding>
                              {Object.keys(profissional).map((erro) => (
                                <ListItem dense>
                                  {rotulosProfissional[erro]}:{" "}
                                  {profissional[erro]}
                                </ListItem>
                              ))}
                            </List>
                          </Box>
                        ) : (
                          "Dados incompletos do novo profissional"
                        )}
                      </ListItem>
                    )}
                  </>
                ))}
                {erroDiretor &&
                  <ListItem>
                    {erroDiretor}
                  </ListItem>
                }
                {erroSemHorario &&
                  <ListItem>
                    {erroSemHorario}
                  </ListItem>
                }
              </List>
            </Alert>
          )}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={2000}
            open={erroFormulario}
          >
            <Alert severity="error">Confira as informações</Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={2000}
            open={formularioInalterado}
          >
            <Alert severity="error">Nenhuma informação alterada</Alert>
          </Snackbar>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loadingEnviar}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {formatarComoTexto(solicitacaoAberta.status) ? (
            <CRTexto
              dados={formularioInicial}
              prof={listaProfissionais}
              capitalSocial={capitalSocial}
            />
          ) : (
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit} autoComplete="off">
                <Box
                  className={classes.grupo}
                  component={Paper}
                  variant="outlined"
                >
                  <Box className={classes.titulo}>
                    <Typography variant="h6">Registro</Typography>
                    <Typography variant="body" paragraph sx={{ fontWeight: 'bold'}}>
                      Para alterações de razão social, nome fantasia, atividade ou capital social, será necessário anexar alteração contratual e CNPJ a seguir.
                    </Typography>
                    <Divider />
                  </Box>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={12} sm={2} >
                      <TextField
                        size="small"
                        margin="normal"
                        fullWidth
                        id="cnpj"
                        name="empresa.cnpj"
                        label="CNPJ"
                        type="text"
                        value={formik.values.empresa.cnpj}
                        autoComplete="off"
                        InputLabelProps={{ shrink: true }}
                        disabled
                        
                      />
                    </Grid>
										<Grid item xs={12} sm={2}>
										<TextField
                        size="small"
                        margin="normal"
                        fullWidth
                        id="inscricao"
                        name="empresa.inscricao"
                        label="Inscrição"
                        type="text"
                        value={formik.values.empresa.inscricao || auth.idusuario}
                        autoComplete="off"
                        InputLabelProps={{ shrink: true }}
                        disabled
                      />
										</Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        size="small"
                        margin="normal"
                        fullWidth
                        id="nome"
                        name="empresa.nome"
                        label="Nome fantasia"
                        value={formik.values.empresa.nome}
                        onChange={formik.handleChange}
                        autoComplete="off"
                        autoFocus
                        InputLabelProps={{ shrink: true }}
                        focused={
                          formularioInicial.nome !== formik.values.empresa.nome
                        }
                        error={
                          formik.touched.empresa?.nome &&
                          Boolean(formik.errors.empresa?.nome)
                        }
                        helperText={
                          formik.touched.empresa?.nome &&
                          formik.errors.empresa?.nome
                        }
                        disabled={!estaPendente('nome') || solicitacaoAberta.status === "1"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        size="small"
                        margin="normal"
                        fullWidth
                        id="razao"
                        name="empresa.razao"
                        label="Razão social"
                        type="text"
                        value={formik.values.empresa.razao}
                        onChange={formik.handleChange}
                        autoComplete="off"
                        InputLabelProps={{ shrink: true }}
                        focused={
                          formularioInicial.razao !==
                          formik.values.empresa.razao
                        }
                        error={
                          formik.touched.empresa?.razao &&
                          Boolean(formik.errors.empresa?.razao)
                        }
                        helperText={
                          formik.touched.empresa?.razao &&
                          formik.errors.empresa?.razao
                        }
                        disabled={!estaPendente('razao') || solicitacaoAberta.status === "1"}
                      />
                    </Grid>

                    <Grid container item direction="row" spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          size="small"
                          select
                          label="Capital social"
                          margin="normal"
                          fullWidth
                          id="capitalsocial_idcapitalsocial"
                          name="empresa.capitalsocial_idcapitalsocial"
                          value={
                            formik.values.empresa.capitalsocial_idcapitalsocial
                          }
                          onChange={formik.handleChange}
                          InputLabelProps={{ shrink: true }}
                          focused={
                            formularioInicial.capitalsocial_idcapitalsocial !==
                            formik.values.empresa.capitalsocial_idcapitalsocial
                          }
                          error={
                            formik.touched.empresa
                              ?.capitalsocial_idcapitalsocial &&
                            Boolean(
                              formik.errors.empresa
                                ?.capitalsocial_idcapitalsocial
                            )
                          }
                          helperText={
                            formik.touched.empresa
                              ?.capitalsocial_idcapitalsocial &&
                            formik.errors.empresa?.capitalsocial_idcapitalsocial
                          }
                          disabled={!estaPendente('capitalsocial_idcapitalsocial') || solicitacaoAberta.status === "1"}
                        >
                          {capitalSocial?.map((cap, index) => (
                            <MenuItem value={cap.idcapitalsocial}>
                              {cap.texto}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          size="small"
                          select
                          label="Tipo de estabelecimento"
                          margin="normal"
                          fullWidth
                          id="idtipo"
                          name="empresa.idtipo"
                          value={formik.values.empresa.idtipo}
                          // onChange={(ev) => onChangeTipo(ev)}
                          onChange={formik.handleChange}
                          InputLabelProps={{ shrink: true }}
                          error={
                            formik.touched.empresa?.idtipo &&
                            Boolean(formik.errors.empresa?.idtipo)
                          }
                          helperText={
                            formik.touched.empresa?.idtipo &&
                            formik.errors.empresa?.idtipo
                          }
                          focused={
                            formularioInicial.idtipo !==
                            formik.values.empresa.idtipo
                          }
                          disabled={!estaPendente('idtipo') || solicitacaoAberta.status === "1"}
                        >
                          <MenuItem value="0">- - -</MenuItem>
                          {tipoDeEstabelecimento?.map((tipo) => (
                            <MenuItem value={tipo.idtipo}>{tipo.tipo}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          size="small"
                          select
                          label="Subtipo de estabelecimento"
                          margin="normal"
                          fullWidth
                          id="idsubtipo"
                          name="empresa.idsubtipo"
                          value={formik.values.empresa.idsubtipo}
                          onChange={formik.handleChange}
                          disabled={!estaPendente('idsubtipo') && !estaPendente('idtipo') || solicitacaoAberta.status === "1"}
                        >
                          <MenuItem value="0">----</MenuItem>
                          {subtipoDeEstabelecimento?.map((sub) => (
                            <MenuItem value={sub.idsubtipo}>
                              {sub.subtipo}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  className={classes.grupo}
                  component={Paper}
                  variant="outlined"
                >
                  <Box className={classes.titulo}>
                    <Typography variant="h6">Localização</Typography>
                    <Typography variant="body" paragraph>
                      Para alteração de endereço, será necessário anexar alteração contratual e CNPJ a seguir.
                    </Typography>
                    <Divider />
                  </Box>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid container item direction="row" spacing={2}>
                      <Grid item>
                        <InputMask
                          mask="99999-999"
                          value={formik.values.empresa.cep}
                          onChange={formik.handleChange}
                          onBlur={(ev) => onBlurCep(ev, formik.setFieldValue)}
                          name="empresa.cep"
                          disabled={desabilitaCEP() || solicitacaoAberta.status === "1"}
                        >
                          {(inputProps) => (
                            <TextField
                              size="small"
                              margin="normal"
                              fullWidth
                              id="cep"
                              name="empresa.cep"
                              label="CEP"
                              autoComplete="off"
                              InputLabelProps={{ shrink: true }}
                              disabled={desabilitaCEP() || solicitacaoAberta.status === "1"}
                              focused={
                                formularioInicial.cep !==
                                formik.values.empresa.cep
                              }
                              error={
                                formik.touched.empresa?.cep &&
                                Boolean(formik.errors.empresa?.cep)
                              }
                              helperText={
                                formik.touched.empresa?.cep &&
                                formik.errors.empresa?.cep
                              }
                              
                            />
                          )}
                        </InputMask>
                        {cepNaoEncontrado && (
                          <Alert severity="warning">Cep não encontrado</Alert>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item direction="row" spacing={2}>
                      <Grid item sm={6} xs={12}>
                        <TextField
                          size="small"
                          margin="normal"
                          multiline
                          fullWidth
                          id="logradouro"
                          name="empresa.logradouro"
                          label="Logradouro"
                          type="text"
                          value={formik.values.empresa.logradouro}
                          onChange={formik.handleChange}
                          autoComplete="off"
                          InputLabelProps={{ shrink: true }}
                          focused={
                            formularioInicial.logradouro !==
                            formik.values.empresa.logradouro
                          }
                          error={
                            formik.touched.empresa?.logradouro &&
                            Boolean(formik.errors.empresa?.logradouro)
                          }
                          helperText={
                            formik.touched.empresa?.logradouro &&
                            formik.errors.empresa?.logradouro
                          }
                          // disabled={loadingCep}
                          disabled={desabilitaLogradouro() || solicitacaoAberta.status === "1"}
                          InputProps={{
                            endAdornment: loadingCep && (
                              <CircularProgress size={20} />
                            ),
                          }}
                          
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          size="small"
                          margin="normal"
                          fullWidth
                          id="numero"
                          name="empresa.numero"
                          label="Número"
                          type="text"
                          value={formik.values.empresa.numero}
                          onChange={formik.handleChange}
                          autoComplete="off"
                          InputLabelProps={{ shrink: true }}
                          focused={
                            formularioInicial.numero !==
                            formik.values.empresa.numero
                          }
                          disabled={!estaPendente('numero') && !estaPendente('cep') || solicitacaoAberta.status === "1"}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <TextField
                          size="small"
                          margin="normal"
                          fullWidth
                          id="complenum"
                          name="empresa.complenum"
                          label="Complemento"
                          type="text"
                          value={formik.values.empresa.complenum}
                          onChange={formik.handleChange}
                          autoComplete="off"
                          InputLabelProps={{ shrink: true }}
                          focused={
                            formularioInicial.complenum !==
                            formik.values.empresa.complenum
                          }
                          disabled={!estaPendente('complenum') && !estaPendente('cep') || solicitacaoAberta.status === "1"}
                        />
                      </Grid>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        size="small"
                        margin="normal"
                        fullWidth
                        id="bairro"
                        name="empresa.bairro"
                        label="Bairro"
                        type="text"
                        value={formik.values.empresa.bairro}
                        onChange={formik.handleChange}
                        autoComplete="off"
                        InputLabelProps={{ shrink: true }}
                        focused={
                          formularioInicial.bairro !==
                          formik.values.empresa.bairro
                        }
                        disabled={loadingCep || !estaPendente('bairro') && !estaPendente('cep') || solicitacaoAberta.status === "1"}
                        InputProps={{
                          endAdornment: loadingCep && (
                            <CircularProgress size={20} />
                          ),
                        }}
                        
                      />
                    </Grid>
                    <Grid item sm={5} xs={10}>
                      <TextField
                        size="small"
                        margin="normal"
                        fullWidth
                        id="cidade"
                        name="empresa.cidade"
                        label="Cidade"
                        type="text"
                        value={formik.values.empresa.cidade}
                        onChange={formik.handleChange}
                        autoComplete="off"
                        InputLabelProps={{ shrink: true }}
                        focused={
                          formularioInicial.cidade !==
                          formik.values.empresa.cidade
                        }
                        error={
                          formik.touched.empresa?.cidade &&
                          Boolean(formik.errors.empresa?.cidade)
                        }
                        helperText={
                          formik.touched.empresa?.cidade &&
                          formik.errors.empresa?.cidade
                        }
                        disabled={loadingCep | !estaPendente('cidade') && !estaPendente('cep') || solicitacaoAberta.status === "1"}
                        InputProps={{
                          endAdornment: loadingCep && (
                            <CircularProgress size={20} />
                          ),
                        }}
                        
                      />
                    </Grid>
                    <Grid item sm={1} xs={2}>
                      <TextField
                        size="small"
                        margin="normal"
                        fullWidth
                        id="uf_endereco"
                        name="empresa.uf_endereco"
                        label="UF"
                        type="text"
                        value={formik.values.empresa.uf_endereco}
                        onChange={formik.handleChange}
                        autoComplete="off"
                        InputLabelProps={{ shrink: true }}
                        focused={
                          formularioInicial.uf_endereco !==
                          formik.values.empresa.uf_endereco
                        }
                        error={
                          formik.touched.empresa?.uf_endereco &&
                          Boolean(formik.errors.empresa?.uf_endereco)
                        }
                        helperText={
                          formik.touched.empresa?.uf_endereco &&
                          formik.errors.empresa?.uf_endereco
                        }
                        disabled={loadingCep | !estaPendente('uf_endereco') && !estaPendente('cep') || solicitacaoAberta.status === "1"}
                        InputProps={{
                          endAdornment: loadingCep && (
                            <CircularProgress size={20} />
                          ),
                        }}
                        
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  className={classes.grupo}
                  component={Paper}
                  variant="outlined"
                >
                  <Box className={classes.titulo}>
                    <Typography variant="h6">Contatos</Typography>
                    <Divider />
                  </Box>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item sm={6} xs={12}>
                      <TextField
                        size="small"
                        margin="normal"
                        fullWidth
                        id="telefone"
                        name="empresa.telefone"
                        label="Telefone"
                        type="text"
                        value={formik.values.empresa.telefone}
                        onChange={formik.handleChange}
                        autoComplete="off"
                        InputLabelProps={{ shrink: true }}
                        focused={
                          formularioInicial.telefone !==
                          formik.values.empresa.telefone
                        }
                        disabled={!estaPendente('telefone') || solicitacaoAberta.status === "1"}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        size="small"
                        margin="normal"
                        fullWidth
                        id="celular"
                        name="empresa.celular"
                        label="Celular"
                        type="text"
                        value={formik.values.empresa.celular}
                        onChange={formik.handleChange}
                        autoComplete="off"
                        InputLabelProps={{ shrink: true }}
                        focused={
                          formularioInicial.celular !==
                          formik.values.empresa.celular
                        }
                        disabled={!estaPendente('celular') || solicitacaoAberta.status === "1"}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        size="small"
                        margin="normal"
                        fullWidth
                        id="email1"
                        name="empresa.email1"
                        label="Email principal"
                        type="text"
                        value={formik.values.empresa.email1}
                        onChange={formik.handleChange}
                        autoComplete="off"
                        InputLabelProps={{ shrink: true }}
                        error={
                          formik.touched.empresa?.email1 &&
                          Boolean(formik.errors.empresa?.email1)
                        }
                        helperText={
                          formik.touched.empresa?.email1 &&
                          formik.errors.empresa?.email1
                        }
                        focused={
                          formularioInicial.email1 !==
                          formik.values.empresa.email1
                        }
                        disabled={!estaPendente('email1') || solicitacaoAberta.status === "1"}
                      />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <TextField
                        size="small"
                        margin="normal"
                        fullWidth
                        id="email2"
                        name="empresa.email2"
                        label="Email alternativo"
                        type="text"
                        value={formik.values.empresa.email2}
                        onChange={formik.handleChange}
                        autoComplete="off"
                        InputLabelProps={{ shrink: true }}
                        error={
                          formik.touched.empresa?.email2 &&
                          Boolean(formik.errors.empresa?.email2)
                        }
                        helperText={
                          formik.touched.empresa?.email2 &&
                          formik.errors.empresa?.email2
                        }
                        focused={
                          formularioInicial.email2 !==
                          formik.values.empresa.email2
                        }
                        disabled={!estaPendente('email2') || solicitacaoAberta.status === "1"}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  className={classes.grupo}
                  component={Paper}
                  variant="outlined"
                >
                  <Box className={classes.titulo}>
                    <Typography variant="h6">
                      Horário Funcionamento (até 4 períodos de atividade)
                    </Typography>
                    <Divider />
                    <Box>
                      <List>
                        {alteracaoNoHorario && (
                          <Alert severity="warning">
                            {textos.alteracaoHorario}
                          </Alert>
                        )}
                      </List>
                    </Box>
                  </Box>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Box m={{ xs: 0.5, md: 2 }}>
                        <Typography variant="body" paragraph>
                          {" "}
                          Informe os horários de funcionamento sempre em pares
                          (início e fim).
                        </Typography>
                        <Typography variant="body" paragraph>
                          Caso o funcionamento passe de um dia, informar de acordo com o exemplo abaixo:
                        </Typography>
                        <Typography variant="body" paragraph>
                          Estabelecimento funciona das <Box fontWeight={800} display={'inline'}>10:00 de
                          segunda-feira até as 02:00 de terça-feira:</Box>
                        </Typography>
                        <Typography variant="body" paragraph>
                          Assim, cadastrar <Box fontWeight={800} display={'inline'}>Segunda-feira: 10:00 - 23:59</Box> e
                          Terça-feira: <Box fontWeight={800} display={'inline'}> 00:00 - 02:00</Box>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {semanaEmpresa.map((dia, index) => (
                        /*!estaPendente(dia) ? (
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <TextField
                                size="small"
                                margin="normal"
                                fullWidth
                                id={dia.name}
                                name={`empresa[${dia}]`}
                                label={rotulosEmpresa[dia]}
                                error={
                                  formik.touched.empresa?.[dia.name] &&
                                  Boolean(formik.errors.empresa?.[dia])
                                }
                                helperText={
                                  formik.touched.empresa?.[dia] &&
                                  formik.errors.empresa?.[dia]
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                focused={
                                  formularioInicial[dia] !==
                                  formik.values.empresa[dia]
                                }
                                value={formik.values.empresa[dia]}
                               disabled
                              />
                          </Box>
                        ): (*/
                          <Box sx={{ display: "flex", gap: 1 }}>
                          <InputMask
                            mask="99:99 - 99:99 + 99:99 - 99:99 + 99:99 - 99:99 + 99:99 - 99:99"
                            value={
                              formik.values.empresa[dia] === null
                                ? ""
                                : formik.values.empresa[dia]
                            }
                            onChange={formik.handleChange}
                            name={`empresa[${dia}]`}
                          >
                            {(inputProps) => (
                              <TextField
                                size="small"
                                margin="normal"
                                fullWidth
                                id={dia.name}
                                name={`empresa[${dia}]`}
                                label={rotulosEmpresa[dia]}
                                error={
                                  formik.touched.empresa?.[dia.name] &&
                                  Boolean(formik.errors.empresa?.[dia])
                                }
                                helperText={
                                  formik.touched.empresa?.[dia] &&
                                  formik.errors.empresa?.[dia]
                                }
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                focused={
                                  formularioInicial[dia] !==
                                  formik.values.empresa[dia]
                                }
                                InputProps={{disabled:  solicitacaoAberta.status === "1"}}
                               
                              />
                            )}
                          </InputMask>
                          {index < semanaEmpresa.length - 1 && (
                            <Tooltip
                              title="Copiar horário para próximo dia"
                              placement="top"
                            >
                              <IconButton onClick={() => copiarHorario(index)}>
                                <SubdirectoryArrowLeftIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                        /*)*/

                      ))}
                    </Grid>
                  </Grid>
                </Box>
                {/* {solicitacaoAberta.status !== '5' && ( */}
                <Box
                   className={classes.grupo}
                   component={Paper}
                   variant="outlined"
                 >
                   <Box className={classes.titulo}>
                     <Typography variant="h6">Assistência Farmacêutica</Typography>
                     <Typography variant="body" paragraph>
                        Nesta área poderão ser adicionados novos profissionais ou serem alterados a 
                        hierarquia de responsabilidade, o vínculo e os horários de assistência do
                        profissional.
                     </Typography>
                     <Typography variant="body" paragraph>
                        Para comunicação do fim da responsabilidade técnica atual, o próprio farmacêutico
                        deverá informar o fato no Acesso Restrito antes do prosseguimento de solicitação
                        de nova responsabilidade técnica pelo estabelecimento.
                     </Typography>
                     <Divider />
                   </Box>
                   <Box
                     sx={{
                       display: "flex",
                       flexDirection: "column",
                       gap: 2,
                     }}
                   >
                     <Box>
                       <List>
                         {!formik.values.lista.some(
                           (profissional) => profissional.pro_tipo === "diretor"
                         ) && (
                           <ListItem>
                             <Alert severity="warning">
                               {textos.presencaRT}
                             </Alert>
                           </ListItem>
                         )}
                       </List>
                     </Box>
                     <FieldArray
                       name="lista"
                       render={(arrayHelpers) =>
                         formik.values.lista.map((prof, index) => (
                           <Box>
                             <Grid columnGap={2} container alignItems="center" justifyContent={{lg:'center'}}>
                               <Grid item>
                                 {listaProfissionais[index] !== undefined ? (
                                   <TextField
                                     size="small"
                                     disabled={
                                       listaProfissionais[index] !== undefined
                                     }
                                     margin="normal"
                                     name={`lista[${index}].cpf`}
                                     label="CPF"
                                     type="text"
                                     id={`lista[${index}].cpf`}
                                     value={formik.values.lista[index].cpf}
                                     autoComplete="off"
                                     InputLabelProps={{ shrink: true }}                                    
                                   />
                                 ) : (
                                   <InputMask
                                     mask="999.999.999-99"
                                     value={formik.values.lista[index].cpf}
                                     onChange={formik.handleChange}
                                   >
                                     {(inputProps) => (
                                       <TextField
                                         size="small"
                                         margin="normal"
                                         name={`lista[${index}].cpf`}
                                         label="CPF"
                                         type="text"
                                         id={`lista[${index}].cpf`}
                                         InputLabelProps={{ shrink: true }}
                                         error={Boolean(
                                           getIn(
                                             formik.errors,
                                             `lista[${index}].cpf`
                                           ) &&
                                             getIn(
                                               formik.touched,
                                               `lista[${index}].cpf`
                                             )
                                         )}
                                         helperText={
                                           getIn(
                                             formik.touched,
                                             `lista[${index}].cpf`
                                           ) &&
                                           getIn(
                                             formik.errors,
                                             `lista[${index}].cpf`
                                           )
                                             ? getIn(
                                                 formik.errors,
                                                 `lista[${index}].cpf`
                                               )
                                             : ""
                                         }
                                       />
                                     )}
                                   </InputMask>
                                 )}
                               </Grid>
                               <Grid item>
                                 {listaProfissionais[index] !== undefined ? (
                                   <TextField
                                     size="small"
                                     disabled
                                     margin="normal"
                                     name={`lista[${index}].dtnasc`}
                                     value={moment(
                                       formik.values.lista[index].dtnasc,
                                       "YYYY-MM-DD"
                                     ).format("DD/MM/YYYY")}
                                     label="Data de nascimento"
                                     autoComplete="off"
                                     InputLabelProps={{ shrink: true }}
                                   />
                                 ) : (
                                   <>
                                     <InputMask
                                       mask="99/99/9999"
                                       value={
                                         dataIgualBanco(
                                           formik.values.lista[index].dtnasc
                                         )
                                           ? moment(
                                               formik.values.lista[index].dtnasc
                                             ).format("DD/MM/YYYY")
                                           : formik.values.lista[index].dtnasc
                                       }
                                       onChange={formik.handleChange}
                                       name={`lista[${index}].dtnasc`}
                                       onBlur={() =>
                                         onBlurCpf(
                                           index,
                                           formik.setFieldValue,
                                           formik.values.lista[index].cpf,
                                           formik.values.lista[index].dtnasc
                                         )
                                       }
                                     >
                                       {(inputProps) => (
                                         <TextField
                                           size="small"
                                           margin="normal"
                                           name={`lista[${index}].dtnasc`}
                                           label="Data de nascimento"
                                           autoComplete="off"
                                           InputLabelProps={{ shrink: true }}
                                           error={Boolean(
                                             getIn(
                                               formik.errors,
                                               `lista[${index}].dtnasc`
                                             ) &&
                                               getIn(
                                                 formik.touched,
                                                 `lista[${index}].dtnasc`
                                               )
                                           )}
                                           helperText={
                                             getIn(
                                               formik.touched,
                                               `lista[${index}].dtnasc`
                                             ) &&
                                             getIn(
                                               formik.errors,
                                               `lista[${index}].dtnasc`
                                             )
                                               ? getIn(
                                                   formik.errors,
                                                   `lista[${index}].dtnasc`
                                                 )
                                               : ""
                                           }
                                         />
                                       )}
                                     </InputMask>
                                   </>
                                 )}
                               </Grid>
                               <Grid item>
                                 <TextField
                                   size="small"
                                   disabled
                                   margin="normal"
                                   sx={{ minWidth: 300 }}
                                   name={`lista[${index}].nome`}
                                   label="Nome"
                                   type="text"
                                   value={formik.values.lista[index].nome}
                                   onChange={formik.handleChange}
                                   autoComplete="off"
                                   InputLabelProps={{ shrink: true }}
                                   InputProps={{
                                     endAdornment: loadingCpf[index] && (
                                       <CircularProgress size={20} />
                                     ),
                                   }}
                                 />
                               </Grid>
                               <Grid item>
                                 <TextField
                                   select
                                   label="Hierarquia"
                                   variant="outlined"
                                   size="small"
                                   margin="normal"
                                   sx={{ minWidth: 300 }}
                                   name={`lista[${index}].pro_tipo`}
                                   id={`lista[${index}].pro_tipo`}
                                   value={formik.values.lista[index]?.pro_tipo}
                                   onChange={formik.handleChange}
                                   InputLabelProps={{ shrink: true }}
                                   InputProps={{disabled:  solicitacaoAberta.status === "1"}}
                                   error={Boolean(
                                     getIn(
                                       formik.errors,
                                       `lista[${index}].pro_tipo`
                                     ) &&
                                       getIn(
                                         formik.touched,
                                         `lista[${index}].pro_tipo`
                                       )
                                   )}
                                   helperText={
                                     getIn(
                                       formik.touched,
                                       `lista[${index}].pro_tipo`
                                     ) &&
                                     getIn(
                                       formik.errors,
                                       `lista[${index}].pro_tipo`
                                     )
                                       ? getIn(
                                           formik.errors,
                                           `lista[${index}].pro_tipo`
                                         )
                                       : ""
                                   }
                                   focused={
                                     listaProfissionais[index]?.pro_tipo !==
                                     formik.values.lista[index].pro_tipo
                                   }
                                 >
                                   <MenuItem value=""> - - -</MenuItem>
                                   <MenuItem value="diretor">Diretor</MenuItem>
                                   <MenuItem value="assistente">
                                     Assistente
                                   </MenuItem>
                                 </TextField>
                               </Grid>
                               <Grid item>
                                 <Box
                                   sx={{
                                     display: "flex",
                                     gap: 1,
                                     alignItems: "center",
                                   }}
                                 >
                                   {openProfissional[index] ? (
                                     <ExpandLessIcon
                                       onClick={() => handleClick(index, false)}
                                       sx={{ "&:hover": { cursor: "pointer" } }}
                                     />
                                   ) : (
                                     <ExpandMoreIcon
                                       onClick={() => handleClick(index, true)}
                                       sx={{ "&:hover": { cursor: "pointer" } }}
                                     />
                                   )}
                                   {listaProfissionais[index] === undefined && (
                                     <Tooltip title="Cancelar inclusão">
                                       <IconButton
                                         onClick={() => {
                                           arrayHelpers.remove(index);
                                           removerProfissionalProvisorio(index);
                                           removerErroBuscaCPF(index);
                                          }
                                         }
                                       >
                                         <DeleteIcon color="error" />
                                       </IconButton>
                                     </Tooltip>
                                   )}
                                 </Box>
                               </Grid>
                               <Grid item>
                                 {erroBuscaCPF.erro[index] && (
                                   <Alert severity="error">
                                     {erroBuscaCPF.msg[index]}
                                   </Alert>
                                 )}
                                 {erroDuploCPF.erro[index] && (
                                   <Alert severity="error">
                                     {erroDuploCPF.msg[index]}
                                   </Alert>
                                 )}
                               </Grid>
                             </Grid>
                             <Grid columnGap={2} container alignItems="center" justifyContent={{lg:'center'}}>
                              {profissionalProvisorio[index] && (
                                <Alert severity="warning">
                                  Profissional com provisória válida até {moment(profissionalProvisorio[index].validade_provisoria).format("DD/MM/YYYY")}
                                </Alert>
                              )
                              }
                              </Grid>
                             <Collapse
                               in={openProfissional[index]}
                               timeout="auto"
                               unmounonexit="true"
                             >
                               <Grid
                                 sx={{
                                   mt: 2,
                                   mx: "auto",
                                   p: 2,
                                   border: "solid 1px lightgray",
                                   borderRadius: "10px",
                                   backgroundColor: "background.main",
                                 }}
                                 container
                                 justifyContent="center"
                               >
                                 <Grid
                                   container
                                   item
                                   direction="column"
                                   md={6}
                                   xs={12}
                                   px={1}
                                 >
                                   <Grid item>
                                     <TextField
                                       select
                                       variant="outlined"
                                       label="Vínculo"
                                       size="small"
                                       margin="normal"
                                       fullWidth
                                       name={`lista[${index}].pro_vinculo`}
                                       id={`lista[${index}].pro_vinculo`}
                                       value={
                                         formik.values.lista[index]?.pro_vinculo
                                       }
                                       onChange={formik.handleChange}
                                       InputLabelProps={{ shrink: true }}
                                       error={Boolean(
                                         getIn(
                                           formik.errors,
                                           `lista[${index}].pro_vinculo`
                                         ) &&
                                           getIn(
                                             formik.touched,
                                             `lista[${index}].pro_vinculo`
                                           )
                                       )}
                                       helperText={
                                         getIn(
                                           formik.touched,
                                           `lista[${index}].pro_vinculo`
                                         ) &&
                                         getIn(
                                           formik.errors,
                                           `lista[${index}].pro_vinculo`
                                         )
                                           ? getIn(
                                               formik.errors,
                                               `lista[${index}].pro_vinculo`
                                             )
                                           : ""
                                       }
                                       focused={
                                         listaProfissionais[index]
                                           ?.pro_vinculo !==
                                         formik.values.lista[index].pro_vinculo
                                       }
                                       disabled={(((solicitacaoAberta.status !== '5') ? false  : true ) || (solicitacaoAberta.status === '1' ? true : false))}
                                     >
                                       <MenuItem value="proprietario">
                                         Proprietario
                                       </MenuItem>
                                       <MenuItem value="contratado">
                                         Contratado
                                       </MenuItem>
                                     </TextField>
                                   </Grid>
                                   <Grid item>
                                     <TextField
                                       disabled
                                       size="small"
                                       margin="normal"
                                       fullWidth
                                       label="Data de início"
                                       name={`lista[${index}].dataini`}
                                       value={moment(
                                         formik.values.lista[index].dataini
                                       ).format("DD/MM/YYYY")}
                                     ></TextField>
                                   </Grid>
                                   <Grid item></Grid>
                                   {/*
                                   <Grid item>
                                     <Field
                                       as={FormControlLabel}
                                       type="checkbox"
                                       name={`lista[${index}].escala`}
                                       onChange={formik.handleChange}
                                       control={<Checkbox />}
                                       label="Profissional trabalha por escala"
                                     />
                                   </Grid>
                                    */}
                                 </Grid>
                                 <Grid
                                   container
                                   item
                                   direction="column"
                                   md={6}
                                   xs={12}
                                   px={1}
                                 >
                                   {semanaProfissional.map(
                                       (dia, indexNumber) => (
                                         <Box sx={{ display: "flex", gap: 1 }}>
                                           <InputMask
                                             mask="99:99 - 99:99 + 99:99 - 99:99 + 99:99 - 99:99 + 99:99 - 99:99"
                                             value={
                                               formik.values.lista[index][dia]
                                             }
                                             onChange={formik.handleChange}
                                             name={`lista[${index}][${dia}]`}
                                           >
                                             {(inputProps) => (
                                               <TextField
                                                 size="small"
                                                 margin="normal"
                                                 fullWidth
                                                 name={`lista[${index}][${dia}]`}
                                                 label={rotulosProfissional[dia]}
                                                 autoComplete="off"
                                                 InputProps={{disabled: solicitacaoAberta.status === "1"}}
                                                 InputLabelProps={{
                                                   shrink: true,
                                                 }}
                                                 error={Boolean(
                                                   getIn(
                                                     formik.errors,
                                                     `lista[${index}][${dia}]`
                                                   ) &&
                                                     getIn(
                                                       formik.touched,
                                                       `lista[${index}][${dia}]`
                                                     )
                                                 )}
                                                 helperText={
                                                   getIn(
                                                     formik.touched,
                                                     `lista[${index}][${dia}]`
                                                   ) &&
                                                   getIn(
                                                     formik.errors,
                                                     `lista[${index}][${dia}]`
                                                   )
                                                     ? getIn(
                                                         formik.errors,
                                                         `lista[${index}][${dia}]`
                                                       )
                                                     : ""
                                                 }
                                                 focused={
                                                   listaProfissionais[index]?.[
                                                     dia
                                                   ] !==
                                                   formik.values.lista[index][
                                                     dia
                                                   ]
                                                 }
                                               />
                                             )}
                                           </InputMask>
                                           {indexNumber <
                                             semanaProfissional.length - 1 && (
                                             <Tooltip
                                               title="Copiar horário para próximo dia"
                                               placement="top"
                                             >
                                               <IconButton
                                                 onClick={() =>
                                                   copiarHorarioProfissional(
                                                     indexNumber,
                                                     index
                                                   )
                                                 }
                                               >
                                                 <SubdirectoryArrowLeftIcon />
                                               </IconButton>
                                             </Tooltip>
                                           )}
                                         </Box>
                                       )
                                     )}
                                 </Grid>
                               </Grid>
                             </Collapse>
                             <Divider sx={{mt:2}}/>
                           </Box>
                         ))
                       }
                     />
                     <Grid item>
                       <Button
                         type="button"
                         onClick={() => adicionarProfissional(formik.values)}
                         variant="outlined"
                         color="secondary"
                         disabled={((solicitacaoAberta.status === "1") ? true  : false )}
                       >
                         Adicionar profissional
                       </Button>
                     </Grid>
                   </Box>
                </Box>  
                {/* )} */}         
                <Box
                  sx={{
                    display: "flex",
                    gap: 5,
                    justifyContent: "center",
                    mb: 2,
                  }}
                >
                  <Button
                    type="button"
                    onClick={() => cancelarAlteracoes(formik.handleReset)}
                    variant="outlined"
                    color="error"
                  >
                    Cancelar
                  </Button>
                  <Button
                    // type="submit"
                    id="btn-avancar"
                    onClick={enviar}
                    variant="outlined"
                    color="primary"
                  >
                    Avançar
                  </Button>
                </Box>
              </form>
            </FormikProvider>
          )}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <ConferirCRT handleClose={handleClose} alteracaoIsenta={alteracaoIsenta}/>
          </Dialog>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  formularioInicial: state.CRTReducer.formularioInicial,
  formularioTemporario: state.CRTReducer.formularioTemporario,
  formularioEditado: state.CRTReducer.formularioEditado,
  listaProfissionais: state.CRTReducer.listaProfissionais,
  listaProfissionaisTemporario: state.CRTReducer.listaProfissionaisTemporario,
  auth: state.AutenticacaoReducer.autenticacao,
  formularioValido: state.CRTReducer.formularioValido,
  solicitacaoAberta: state.CRTReducer.solicitacaoAberta,
  capitalSocial: state.CRTReducer.capitalSocial,
  tipoDeEstabelecimento: state.CRTReducer.tipoDeEstabelecimento,
  subtipoDeEstabelecimento: state.CRTReducer.subtipoDeEstabelecimento,
});
export default connect(mapStateToProps)(FormularioCRT);
