import { Box, Typography, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Paper } from "@mui/material";
import { Link } from "@mui/material";
import { connect } from "react-redux";

const Pagar = ({
  protocolo,
  solicitacaoAberta,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
        }}
      >
        <Box
          sx={{
            p: { xs: 1, md: 3 },
          }}
          component={Paper}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
              alignItens: "center",
            }}
          >
            <Typography variant="h4" color="primary">
              Alteração de CR solicitada
            </Typography>
            <Box
              sx={{
                backgroundColor: "lightgray",
                p: 1,
                borderRadius: "10px",
                width: "max-content",
              }}
            >
              <Typography
                variant="h6"
                color="primary"
                sx={{
                  "&:hover": { cursor: "pointer", textDecoration: "underline" },
                }}
                onClick={() => navigate(`/histSolic/${protocolo}`)}
              >
                {protocolo}
              </Typography>
            </Box>
          </Box>
          <Typography variant="body" paragraph>
            Sua solicitação será analisada após a compensação do pagamento. O prazo para
            finalização da solicitação é de 07 dias após o pagamento do boleto. {<br></br>}
            E, caso haja pendências, o estabelecimento será notificado, sendo necessário
            regularizá-las dentro do prazo de 07 dias mencionado, independentemente do
            número de ocorrências.
            .
          </Typography>
          <Box>
            {solicitacaoAberta?.boleto ? (
              <>
                <Link
                  target="_blank"
                  href={solicitacaoAberta?.boleto || ""}
                  sx={{
                    float: "right",
                    m: 2,
                  }}
                >
                  Visualizar em tela cheia
                </Link>
                <embed
                  src={solicitacaoAberta?.boleto || ""}
                  width={"100%"}
                  height={"900"}
                />
              </>
            ) : (
              <Typography variant="subtitle">
                Boleto indisponível no momento
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  solicitacaoAberta: state.CRTReducer.solicitacaoAberta,
});
export default connect(mapStateToProps)(Pagar);
