import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { connect } from "react-redux";
import PagamentoRealizado from "../../../components/crt/pagamento/pagamentoRealizado";
import PagamentoIndisponivel from "../../../components/crt/pagamento/pagamentoIndisponivel";
import PagamentoIsento from "../../../components/crt/pagamento/pagamentoIsento";
import Pagar from "../../../components/crt/pagamento/pagar";
import { getSolicAberta, setLoading, getIsencao } from "../../../store/actions/CRTActions";
import Loading from "../../../components/loading";
import { Button, Typography } from "@mui/material";

const Pagamento = ({ solicitacao, loading, idEmp, isIsento }) => {
  const navigate = useNavigate();
  
  
  const { handleActive } = useOutletContext();
  useEffect(() => {
    setLoading(true);
    handleActive(2);
    (async () => {
      const isento = await getIsencao(idEmp)
      const res = await getSolicAberta(idEmp);
    })();
    setLoading(false);
  }, []);
  return (
    <>
        <Typography variant="body2">Caso tenha algum problema com boleto gerado no dia 19/09/2024 entre em contato com o CRF/RS.</Typography>
      {loading ? (
        <Loading loading={loading} />
      ) : solicitacao.status === "0" || solicitacao.status === "7" ? (
        <PagamentoIndisponivel />
      ) : isIsento ? (
        <PagamentoIsento
          protocolo={solicitacao.solic}
          navegar={() => navigate(`/histSolic/${solicitacao.solic}`)}
        />
      ) : solicitacao.status !== "3" && solicitacao.status !== "7" ? (
        <PagamentoRealizado
          protocolo={solicitacao.solic}
          navegar={() => navigate(`/histSolic/${solicitacao.solic}`)}
        />
      ) : (
        <Pagar
          protocolo={solicitacao.solic}
        />
      )}
      <Box
        sx={{
          display: "flex",
          gap: 5,
          justifyContent: "center",
          mb: 2,
        }}
      >
        <Button
          type="button"
          onClick={() => navigate('/empSolic/anexos')}
          variant="outlined"
          color="error"
        >
          Voltar
        </Button>
        <Button
          onClick={() => navigate("/histSolic")}
          variant="outlined"
          color="primary"
        >
          Ir para histórico
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  solicitacao: state.CRTReducer.solicitacaoAberta,
  idEmp: state.AutenticacaoReducer.autenticacao.idusuario,
  isIsento: state.CRTReducer.isIsento,
});
export default connect(mapStateToProps)(Pagamento);
