import { Box} from "@mui/system";
import {IconButton, Link, Tooltip} from "@mui/material";
import { Paper, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const PagamentoIsento = ({protocolo, navegar}) => {
    return(
			<Box component={Paper} sx={{display:'flex', flexDirection:'column', gap: 3, p:3, alignItems:'center'}}>
				<Typography variant='h4'>Isento de pagamento!<Tooltip title="Estabelecimento isento de pagamento de taxa."><IconButton><InfoIcon /></IconButton></Tooltip></Typography>
				<Box>
					<Typography variant='h6'>Acesse sua solicitação no link abaixo:</Typography>
					<Box sx={{p:4, backgroundColor:'lightgray', display:'flex',justifyContent:'center'}}>
						<Link 
							onClick={() => navegar()}
							sx={{'&:hover':{cursor:'pointer'}}}
						>
							{protocolo}
						</Link>
					</Box>
					<Typography paragraph>
						Aguarde a análise do seu protocolo e acompanhe pelo histórico de solicitações no e-CRF.
						Caso as alterações envolvam dados de assistência farmacêutica, os profissionais
						envolvidos e o responsável técnico devem aprovar as mudanças no seu ambiente do	e-CRF.
					</Typography>
					<Typography paragraph>
						O prazo para finalização da solicitação é de 07 dias após o pagamento do
						boleto.
						A solicitação abrange todo o processo, desde a análise inicial até a validação do(s)
						farmacêutico(s) envolvido(s) e/ou responsável técnico. Tanto o estabelecimento
						quanto o(s) farmacêutico(s) precisam estar cientes do prazo de 07 dias para realizar
						as ações necessárias, visando a aprovação da solicitação.
					</Typography>
			</Box>
			</Box>
    )
}
export default PagamentoIsento;